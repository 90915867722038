function Title({containerClass, children}) {

    return (
        <div className={containerClass}>
            <p className='title-decoration'>x</p>
            <h1 className='title-main'>move2x</h1>
            <h2 className='title-secondary'>{children}</h2>
            <p className='title-decoration'>^</p>
        </div>
    );
}

export default Title;