import { baseUrl } from "../Utility/baseServerAddress";

export async function loginAsync(formData) {
    const url = `${baseUrl}/api/v1/user/login`;
    const body = JSON.stringify(formData);
    const headers = { "Content-Type": "application/json" };
  
    const response = await fetch(url, { method: "POST", body, headers });
  
    if (!response.ok) {
      throw new Error("Failed to log in");
    }

    const data = await response.text();
    return {token: data}
  }

  export async function registerUserAsync(userName, password, rawKey) {
  const url = `${baseUrl}/api/v1/user`;
  const key = encodeURIComponent(rawKey);
    const body = JSON.stringify({userName, password, key});
    const headers = { "Content-Type": "application/json" };
  
    const response = await fetch(url, { method: "POST", body, headers });
  
    if (!response.ok) {
      throw new Error("Failed to register");
    }
  
    const data = await response.json();
    return data; 
}

// To implement this you will first need to create a GetAll method in the API

// export async function deleteUserAsync(id, rawKey) {
//   const key = encodeURIComponent(rawKey);
//   const url = `/api/v1/user/${id}?key=${key}`;
//     const headers = { "Content-Type": "application/json" };
  
//     const response = await fetch(url, { method: "POST", headers });
  
//     if (!response.ok) {
//       throw new Error("Failed to delete user");
//     }
// }
