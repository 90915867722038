import React from "react";
import { useState, useEffect } from "react";
import DataTable from "../Layouts/DataTable";
import {
    getAllGameConfigsAsync,
    getGameConfigByIdAsync,
    updateGameConfigByIdAsync,
    createGameConfigAsync,
    deleteGameConfigByIdAsync,
    setDefaultGameConfigAsync,
    getDefaultGameConfigAsync
} from "../Services/GameConfigFacade";
import useAuth from "../Hooks/useAuth";
import Title from "../Components/Title";

function GameConfigs() {
    const [gameConfigs, setGameConfigs] = useState([]);
    const [status, setStatus] = useState("loading");
    const [defaultConfig, setDefaultConfig] = useState(null);
    const { auth } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getAllGameConfigsAsync(auth.token);
                setGameConfigs(data);
                handleGetDefaultConfig();
                setStatus("retrieved");
            } catch (error) {
                console.error(error);
                setStatus("error");
            }
        };

        fetchData();
    }, [auth.token]);

    let titleText = "Loading...";

    switch (status) {
        case "error":
            titleText = "Error";
            break;
        case "retrieved":
            titleText = "Game Configs";
            break;
        case "editing":
            titleText = "Editing";
            break;
        case "saving":
            titleText = "Saving...";
            break;
        case "loading":
            titleText = "Loading...";
            break;
        case "creating":
            titleText = "Creating";
            break;
        default:
            titleText = "Game Configs";
    }

    const handleDeleteRequest = async (id) => {
        try {
            const response = await deleteGameConfigByIdAsync(id, auth.token);
            // console.log(response);
        } catch (error) {
            console.error(error);
            setStatus("error");
        }
    }

    const handleEditRequest = async (id) => {
        try {
            const response = await updateGameConfigByIdAsync(id, auth.token);
        } catch (error) {
            console.error(error);
            setStatus("error");
        }
    }

    const handleCreateRequest = async (gameConfig) => {
        try {
            const gameConfigId = await createGameConfigAsync(gameConfig, auth.token);
            const newGameConfig = await getGameConfigByIdAsync(gameConfigId, auth.token);
            return newGameConfig;
        } catch (error) {
            console.error(error);
            setStatus("error");
        }
    }

    const handleGetDefaultConfig = async () => {
        try {
            const defaultGameConfig = await getDefaultGameConfigAsync(auth.token);
            // this makes sure the default config is in sync with the server
            setDefaultConfig(defaultGameConfig);
            return defaultConfig;
        } catch (error) {
            console.error(error);
            setStatus("error");
        }
    }

    const handleSetDefaultConfig = async (gameConfig) => {
        try {
            const newDefaultConfigId = await setDefaultGameConfigAsync(gameConfig, auth.token);
            setDefaultConfig(newDefaultConfigId);
        } catch (error) {
            console.error(error);
            setStatus("error");
        }
    }

    const gameConfigFormat = {
        stationSequence: "",
        stickSequence: ""
    }

    return (
        <>
            <Title containerClass="datatable-title">{titleText}</Title>
            <DataTable
                datasets={gameConfigs}
                setDatasets={setGameConfigs}
                parentStatus={status}
                setParentStatus={setStatus}
                parentContext="game config"
                createRequest={handleCreateRequest}
                editRequest={handleEditRequest}
                deleteRequest={handleDeleteRequest}
                getRequest={getGameConfigByIdAsync}
                createFormat={gameConfigFormat}
                firstName="Game config"
                defaultConfig={defaultConfig}
                setDefaultConfig={handleSetDefaultConfig}
            >
            </DataTable>
        </>
    )
}

export default GameConfigs;