import { baseUrl } from "../Utility/baseServerAddress";

export async function getAllCoresAsync(token) {
    const url = `${baseUrl}/api/v1/core/all`;
    const headers = { "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`};

    const response = await fetch(url, { method: "GET", headers });

    if (!response.ok) {
        throw new Error("Failed to get all cores");
    }

    const data = await response.json();
    return data;
}

export async function getCoreByIdAsync(id, token) {
    const url = `${baseUrl}/api/v1/core/${id}`;
    const headers = { "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`};
    const response = await fetch(url, { method: "GET", headers });

    if (!response.ok) {
        throw new Error(`Failed to get core with id: ${id}`);
    }

    const data = await response.json();
    return data;
}

export async function deleteCoreByIdAsync(id, token) {
    const url = `${baseUrl}/api/v1/core/${id}`;
    const headers = { "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`};
    const response = await fetch(url, { method: "DELETE", headers });

    if (!response.ok) {
        throw new Error(`Failed to delete core with id: ${id}`);
    }
}

export async function updateCoreByIdAsync(core, token) {
    const url = `${baseUrl}/api/v1/core`;
    const body = JSON.stringify(core);
    const headers = { "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`};
    const response = await fetch(url, { method: "PUT", body, headers });

    if (!response.ok) {
        throw new Error(`Failed to update core`);
    }
}

export async function createCoreByIdAsync(core, token) {
    const url = `${baseUrl}/api/v1/core`;
    const body = JSON.stringify(core);
    const headers = { "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`};
    const response = await fetch(url, { method: "POST", body, headers });

    if (!response.ok) {
        throw new Error(`Failed to create core`);
    }

    const returnedId = await response.json();
    // console.log("from request"+ returnedId);

  return returnedId;
}