import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {Link, useMatch, useResolvedPath } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
      };
    
      const closeMenu = () => {
        setIsMenuOpen(false);
      };

    return (
        <nav className="nav">
            <div className="logo-container">
                <Link to="/" className="site-title">move2x</Link>
            </div>
            {isMobile && (
        <>
          <input type="checkbox" id="checkbox" checked={isMenuOpen} onChange={handleMenuToggle} />
          <label htmlFor="checkbox" className="hamburger-button">
            <FontAwesomeIcon icon={faBars} />
          </label>
        </>
      )}
      <ul className={`nav-list ${isMobile && isMenuOpen ? "show" : ""}`}>
        <CustomLink to="/" onClick={closeMenu}>
          Cores
        </CustomLink>
        <CustomLink to="/sticks" onClick={closeMenu}>
          Sticks
        </CustomLink>
        <CustomLink to="/gameconfigs" onClick={closeMenu}>
          Game Configs
        </CustomLink>
        {/* <CustomLink to="/users" onClick={closeMenu}>
          Users
        </CustomLink> */}
      </ul>
    </nav>
    )
}

function CustomLink({to, children, onClick, ...props}) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });

    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to} onClick={onClick} {...props}>
                {children}
            </Link>
        </li>
    )
}

export default Navbar;