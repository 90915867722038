import { baseUrl } from "../Utility/baseServerAddress";

export async function getAllSticksAsync(token) {
    const url = `${baseUrl}/api/v1/stick/all`;
    const headers = { "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`};

    const response = await fetch(url, { method: "GET", headers });

    if (!response.ok) {
        throw new Error("Failed to get all sticks");
    }

    const data = await response.json();
    return data;
}

export async function getStickByIdAsync(id, token) {
    const url = `${baseUrl}/api/v1/stick/${id}`;
    const headers = { "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`};
    const response = await fetch(url, { method: "GET", headers });

    if (!response.ok) {
        throw new Error(`Failed to get stick with id: ${id}`);
    }

    const data = await response.json();
    return data;
}

export async function deleteStickByIdAsync(id, token) {
    const url = `${baseUrl}/api/v1/stick/${id}`;
    const headers = { "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`};
    const response = await fetch(url, { method: "DELETE", headers });

    if (!response.ok) {
        throw new Error(`Failed to delete stick with id: ${id}`);
    }
}

export async function updateStickByIdAsync(stick, token) {
    const url = `${baseUrl}/api/v1/stick`;
    const body = JSON.stringify(stick);
    const headers = { "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`};
    const response = await fetch(url, { method: "PUT", body, headers });

    if (!response.ok) {
        throw new Error(`Failed to update stick`);
    }
}

export async function createStickByIdAsync(stick, token) {
    const url = `${baseUrl}/api/v1/stick`;
    const body = JSON.stringify(stick);
    const headers = { "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`};
    const response = await fetch(url, { method: "POST", body, headers });

    if (!response.ok) {
        throw new Error(`Failed to create stick`);
    }

    const returnedId = await response.json();
    // console.log("from request"+ returnedId);

  return returnedId;
}