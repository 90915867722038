import { baseUrl } from "../Utility/baseServerAddress";

export async function getAllGameConfigsAsync(token) {
    const url = `${baseUrl}/api/v1/gameconfig/all`;
    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
    };

    const response = await fetch(url, { method: "GET", headers });

    if (!response.ok) {
        throw new Error("Failed to get all game configs");
    }

    const data = await response.json();
    return data;
}

export async function getGameConfigByIdAsync(id, token) {
    // console.log(`game config id: ${id}`)
    const url = `${baseUrl}/api/v1/gameconfig/${id}`;
    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
    };
    const response = await fetch(url, { method: "GET", headers });

    if (!response.ok) {
        throw new Error(`Failed to get game config with id: ${id}`);
    }

    const data = await response.json();
    return data;
}

export async function deleteGameConfigByIdAsync(id, token) {
    const url = `${baseUrl}/api/v1/gameconfig/${id}`;
    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
    };
    const response = await fetch(url, { method: "DELETE", headers });

    if (!response.ok) {
        throw new Error(`Failed to delete game config with id: ${id}`);
    }
}

export async function updateGameConfigByIdAsync(gameConfig, token) {
    const url = `${baseUrl}/api/v1/gameconfig`;
    const body = JSON.stringify(gameConfig);
    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
    };
    const response = await fetch(url, { method: "PUT", body, headers });

    if (!response.ok) {
        throw new Error(`Failed to update game config`);
    }
}

export async function createGameConfigAsync(gameConfig, token) {
    const url = `${baseUrl}/api/v1/gameconfig`;
    const body = JSON.stringify(gameConfig);
    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
    };
    const response = await fetch(url, { method: "POST", body, headers });

    if (!response.ok) {
        throw new Error(`Failed to create game config`);
    }

    const returnedId = await response.json();
    // console.log("from request"+ returnedId);

    return returnedId;
}

export async function setDefaultGameConfigAsync(gameConfig, token) {
    const url = `${baseUrl}/api/v1/gameconfig`;
    const body = JSON.stringify({ gameConfig });
    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
    };
    const response = await fetch(url, { method: "POST", body, headers });

    if (!response.ok) {
        throw new Error(`Failed to set default game config`);
    }
}

export async function getDefaultGameConfigAsync(token) {
    const url = `${baseUrl}/api/v1/gameconfig/default`;
    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
    };
    const response = await fetch(url, { method: "GET", headers });

    if (!response.ok) {
        throw new Error(`Failed to get default game config`);
    }

    const data = await response.json();
    return data;
}