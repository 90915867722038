import React, { useState } from "react";
import Button from "../Components/Button";

function DataForm({ onSubmit, onCancel, initialValues }) {
  const [values, setValues] = useState(initialValues || {});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(values);
  };

  const handleCancel = () => {
    onCancel();
  };

  const labelInputFields = Object.keys(values).map((key) => {
    if (key === "id") {
      return (
        <div className="dataform__input-container" key={key}>
          <label>
            {key[0].toUpperCase() + key.slice(1)}
            <input
              type="text"
              name={key}
              value={values[key]}
              className="dataform__input"
              disabled={true}
            />
          </label>
        </div>
      );
    }
  
    return (
      <div className="dataform__input-container" key={key}>
        <label>
          {key[0].toUpperCase() + key.slice(1)}
          <input
            type="text"
            name={key}
            value={values[key]}
            className="dataform__input"
            onChange={handleChange}
            disabled={key === "id"}
          />
        </label>
      </div>
    );
  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        {labelInputFields}
        <div className="dataform__buttons-container">
        <Button className="regular-button" type="submit">
          Save
        </Button>
        <Button
          className="regular-button"
          onClick={handleCancel}
        >
          Cancel
        </Button>

        </div>
      </form>
    </>
  );
}

export default DataForm;