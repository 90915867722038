import React from "react";
import TableRecord from "./TableRecord";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import Button from "./Button";

function TableRows({
    datasets,
    expandedRow,
    handleRowClick,
    handleEditClick,
    handleDeleteClick,
    firstName,
    secondName,
    defaultConfig,
    setDefaultConfig
}) {
    // console.log(defaultConfig);

    return (
        <>
            {datasets.map((data) => (
                <ul key={data.id}>
                    <div className={`name ${expandedRow.includes(data.id) && 'active'}`} onClick={() => handleRowClick(data.id)}>
                        <TableRecord className="name-value">{
                            firstName === 'Game config'
                                ? `${firstName} ${data[Object.keys(data)[0]]}`
                                : `${firstName}${data[Object.keys(data)[1]]} ${secondName}${data[Object.keys(data)[2]]}`
                        }
                        </TableRecord>
                        {/* {firstName === 'Game config' && data.id === defaultConfig && (
                            <FontAwesomeIcon icon={solidStar} />
                        )}
                        {firstName === 'Game config' && data.id !== defaultConfig && (
                               
                            <FontAwesomeIcon icon={regularStar} onClick={() => setDefaultConfig(data)}/>
                        )} */}
                        <FontAwesomeIcon className="expansion-arrow" icon={faAngleDown} />
                    </div>
                    {expandedRow.includes(data.id) && (
                        <div key="values" className="values-container">
                            {Object.entries(data).map(([key, value]) => (
                                <div key={key} className="value-container">
                                    <TableRecord key={key} label={key}>{value}</TableRecord>
                                </div>
                            ))}
                            <div className="crud-buttons">
                                <Button className="regular-button" onClick={() => handleEditClick(data)}>Edit</Button>
                                <Button className="regular-button" onClick={() => handleDeleteClick(data.id)}>Delete</Button>
                            </div>
                        </div>
                    )}
                    <div className="record-separator"></div>
                </ul>
            ))}
        </>
    );
}

export default TableRows;