function Button({type, onClick, children, className, disabled, style }) {
    const handleClick = onClick || (() => {});

    return (
    <button 
    type = {type ? type : "button"}
    style = {style}
    onClick={handleClick}
    className={className}
    disabled={disabled}>
    {children}
    </button>
    );
    }

    export default Button;