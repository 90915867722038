import React from "react";
import { useState, useEffect } from "react";
import DataTable from "../Layouts/DataTable";
import { getAllSticksAsync, getStickByIdAsync, updateStickByIdAsync, deleteStickByIdAsync, createStickByIdAsync } from "../Services/StickFacade"
import useAuth from "../Hooks/useAuth";
import Title from "../Components/Title";

function Sticks() {
  const [sticks, setSticks] = useState([]);
  const [status, setStatus] = useState("loading");
  const { auth } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllSticksAsync(auth.token);
        setSticks(data);
        setStatus("retrieved");
      } catch (error) {
        console.error(error);
        setStatus("error");
      }
    };

    fetchData();
  }, [auth.token]);

  let titleText = "Loading...";

  switch (status) {
    case "error":
      titleText = "Error";
      break;
    case "retrieved":
      titleText = "Sticks";
      break;
    case "editing":
      titleText = "Editing";
      break;
    case "saving":
      titleText = "Saving...";
      break;
    case "creating":
      titleText = "Creating";
      break;
    case "loading":
      titleText = "Loading...";
      break;
    default:
      titleText = "Sticks";
  }

  const handleDeleteRequest = async (id) => {
    try {
      const response = await deleteStickByIdAsync(id, auth.token);
      // console.log(response);
    } catch (error) {
      console.error(error);
      setStatus("error");
    }
  }

  const handleEditRequest = async (id) => {
    try {
      const response = await updateStickByIdAsync(id, auth.token);
    } catch (error) {
      console.error(error);
      setStatus("error");
    }
  }

  const handleCreateRequest = async (stick) => {
    try {
      const stickId = await createStickByIdAsync(stick, auth.token);
      const newStick = await getStickByIdAsync(stickId, auth.token);
      return newStick;
    } catch (error) {
      console.error(error);
      setStatus("error");
    }
  }

  const stickFormat = {
    teamNumber: "",
    stickNumber: "",
    accessToken: ""
  }

  return (
    <>
      <Title containerClass="datatable-title">{titleText}</Title>
      <DataTable
        datasets={sticks}
        setDatasets={setSticks}
        parentStatus={status}
        setParentStatus={setStatus}
        parentContext="stick"
        createRequest={handleCreateRequest}
        editRequest={handleEditRequest}
        deleteRequest={handleDeleteRequest}
        getRequest={getStickByIdAsync}
        createFormat={stickFormat}
        firstName="Team"
        secondName="Stick"
      >
      </DataTable>
    </>
  )
}

export default Sticks;