import React from "react";
import { useState, useEffect } from "react";
import DataTable from "../Layouts/DataTable";
import { getAllCoresAsync, deleteCoreByIdAsync, updateCoreByIdAsync, createCoreByIdAsync, getCoreByIdAsync } from "../Services/CoreFacade"
import useAuth from "../Hooks/useAuth";
import Title from "../Components/Title";

function Cores() {
  const [cores, setCores] = useState([]);
  const [status, setStatus] = useState("loading");
  const { auth } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAllCoresAsync(auth.token);
        setCores(data);
        setStatus("retrieved");
      } catch (error) {
        console.error(error);
        setStatus("error");
      }
    };

    fetchData();
  }, [auth.token]);

  let titleText = "Loading...";

  switch (status) {
    case "error":
      titleText = "Error";
      break;
    case "retrieved":
      titleText = "Cores";
      break;
    case "editing":
      titleText = "Editing";
      break;
    case "saving":
      titleText = "Saving...";
      break;
    case "loading":
      titleText = "Loading...";
      break;
    case "creating":
      titleText = "Creating";
      break;
    default:
      titleText = "Cores";
  }

  const handleDeleteRequest = async (id) => {
    try {
      const response = await deleteCoreByIdAsync(id, auth.token);
      // console.log(response);
    } catch (error) {
      console.error(error);
      setStatus("error");
    }
  }

  const handleEditRequest = async (id) => {
    try {
      const response = await updateCoreByIdAsync(id, auth.token);
    } catch (error) {
      console.error(error);
      setStatus("error");
    }
  }

  const handleCreateRequest = async (core) => {
    try {
      const coreId = await createCoreByIdAsync(core, auth.token);
      const newCore = await getCoreByIdAsync(coreId, auth.token);
      return newCore;
    } catch (error) {
      console.error(error);
      setStatus("error");
    }

  }

  const coreFormat = {
    teamNumber: "",
    stationNumber: "",
    accessToken: "",
    gameConfigId: "",
  }

  return (
    <>
      <Title containerClass="datatable-title">{titleText}</Title>
      <DataTable
        datasets={cores}
        setDatasets={setCores}
        parentStatus={status}
        setParentStatus={setStatus}
        parentContext="core"
        createRequest={handleCreateRequest}
        editRequest={handleEditRequest}
        deleteRequest={handleDeleteRequest}
        createFormat={coreFormat}
        getRequest={getCoreByIdAsync}
        firstName="Team"
        secondName="Station"
      >
      </DataTable>
    </>
  )
}

export default Cores;