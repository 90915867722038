import React from "react";

// represents one attribute in the table such as Station Number for cores
function TableRecord({ children, label, className }) {
    return (
      <li className={`table-record ${className}`} >
        {label ? (
          <>
            <span className="table-record__label">{label}:&nbsp;</span>
            <span className="table-record__value">{children}</span>
          </>
        ) : (
          <>{children}</>
        )}
      </li>
    );
  }

export default TableRecord;