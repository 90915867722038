import React, { useState, useEffect } from "react";
import TableHeaders from "../Components/TableHeaders";
import TableRows from "../Components/TableRows";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../Hooks/useAuth";
import DataForm from "./DataForm";
import DeleteConfirmation from "../Components/DeleteConfirmation";

function DataTable({
  datasets,
  setDatasets,
  parentStatus,
  setParentStatus,
  parentContext,
  deleteRequest,
  editRequest,
  createRequest,
  firstName,
  secondName,
  getRequest,
  createFormat,
  defaultConfig,
  setDefaultConfig
}) {
  const [expandedRow, setExpandedRow] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationBoxOpen, setIsConfirmationBoxOpen] = useState(false);
  const [confirmationBoxContent, setConfirmationBoxContent] = useState(null);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [status, setStatus] = useState("default")
  const { auth } = useAuth();

  useEffect(() => {
    if (status === "editing") {
      openModal(itemToEdit);
    } else if (status === "creating") {
      openModal(createFormat);
    }
  }, [status]);

  if (parentStatus === "loading") {
    return <div></div>;
  }

  if (parentStatus === "error") {
    return <div>Failed to retrieve your items. Please reload</div>;
  }


  const handleRowClick = (id) => {
    if (expandedRow.includes(id)) {
      setExpandedRow(expandedRow.filter((rowId) => rowId !== id));
    } else {
      setExpandedRow([id]);
    }
  };

  const handleDeleteClick = async (id) => {
    closeModal();
    try {
      await deleteRequest(id);

      const newDatasets = datasets.filter((item) => item.id !== id);
      setDatasets(newDatasets);

      if (expandedRow === id) {
        setExpandedRow(null);
      }

      closeConfirmationBox();
    } catch (error) {
      console.error("Failed to delete the item: ", error);
    }
  };

  const handleCreateClick = () => {
    closeModal();
    setStatus("creating");
    setParentStatus("creating");
  }

  const handleEditClick = async (object) => {
    closeModal();
    try {
      if (!object.id) {
        console.error("The item to edit is missing an id property");
        return;
      }
      const itemToEdit = await getRequest(object.id, auth.token);

      setItemToEdit(itemToEdit);
      setStatus("editing");
      setParentStatus("editing");
    } catch (error) {
      console.error("Failed to get the item to edit: ", error);
      setStatus("error");
    }
  };

  const handleSetDefaultClick = async (object) => {
    try {
      if (!object.id) {
        console.error("Cannot set default because item is missing an id property");
        return;
      }

      setDefaultConfig(object);

    } catch (error) {
      console.error("Failed to set default game config ", error);
      setStatus("error");
    }
  }

  const handleFormSubmit = async (values) => {
    try {
      let newDataset;
      // console.log("status when submitting "+ status);
      if (status === "editing") {
        if (expandedRow === values.id) {
          setExpandedRow(null);
        }
        await editRequest(values);
        const newDatasets = datasets.map((item) => {
          if (item.id === values.id) {
            return values;
          } else {
            return item;
          }
        });
        setDatasets(newDatasets);
        newDataset = values;
      } else {
        // console.log("values " + values.id);
        newDataset = await createRequest(values);
        // console.log("new dataset:" + newDataset);
        setDatasets([...datasets, newDataset]);
      }
      closeModal();
    } catch (error) {
      console.error("Failed to submit the form: ", error);
    }
  };

  const openModal = (itemToEdit) => {
    setIsModalOpen(true);

    setModalContent(
      <DataForm
        initialValues={itemToEdit}
        onSubmit={handleFormSubmit}
        onCancel={closeModal}
      />
    );
  };

  const openConfirmationBox = async (id) => {
    // console.log("confirmation box called");
    setIsConfirmationBoxOpen(true);
    setStatus("deleting");
    setConfirmationBoxContent(
      <DeleteConfirmation
        context={parentContext}
        onNo={closeConfirmationBox}
        onYes={async () => await handleDeleteClick(id)}
      />
    )
  }

  const closeModal = () => {
    setIsModalOpen(false);
    setStatus("default");
    setParentStatus("default");
    setModalContent(null);
  };

  const closeConfirmationBox = () => {
    setIsConfirmationBoxOpen(false);
    setStatus("default");
    setParentStatus("default");
    setConfirmationBoxContent(null);
  };

  return (
    <>
      {/* <div className="data-container"> */}

      <div className="table">
        <TableHeaders datasets={datasets} />
        <TableRows datasets={datasets}
          firstName={firstName}
          secondName={secondName}
          expandedRow={expandedRow}
          handleRowClick={handleRowClick}
          handleEditClick={handleEditClick}
          handleDeleteClick={openConfirmationBox}
          defaultConfig={defaultConfig}
          setDefaultConfig={handleSetDefaultClick}
        />
        <div className="header-separator"></div>
      </div>
      <div>
        <FontAwesomeIcon icon={faCirclePlus} className="button-create" onClick={handleCreateClick} />
      </div>
      {isModalOpen && (
        <div className="dataform-container">
          {modalContent}
        </div>
      )}
      {isConfirmationBoxOpen && (
        <div>

          {confirmationBoxContent}
        </div>
      )}
      {/* </div> */}
    </>
  );
}

export default DataTable;