import Button from "../Components/Button";
import React, { useState } from "react";
import { loginAsync } from "../Services/UserFacade";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import Title from "../Components/Title";
import useAuth from "../Hooks/useAuth";
import { useNavigate, useLocation } from 'react-router-dom';

function LoginForm() {
    const [formData, setFormData] = useState({ username: "", password: "" });
    const [status, setStatus] = useState("typing");
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const { setAuth } = useAuth();

    const handleInputChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setStatus("submitting");
        setFormData({ username: "", password: "" });
        try {
            const response = await loginAsync(formData);
            const token = response.token;
            setAuth(() => ({ isAuthenticated: true, token }));
            navigate(from, { replace: true });
            
        } catch {
            setStatus("error");
        }
    };

    function handleRegisterClick() {
        navigate('/register');
    }

    let titleText = "Sign in";

    if (status === "error") {
        titleText = "Error";
    } else if (status === "submitting") {
        titleText = "Signing in...";
    }


    return (
        <>
            <Title containerClass="login-title">{titleText}</Title>
            <form onSubmit={handleSubmit} className="login-form">
                <div className="login__input-container">
                    <input
                        className="login__input-field"
                        type="text"
                        name="username"
                        spellCheck="false"
                        value={formData.username || ""}
                        placeholder="Username"
                        onChange={handleInputChange}
                    />
                    <FontAwesomeIcon icon={faUser} className="login-icon" />
                </div>
                <div className="login__input-container">
                    <input
                        className="login__input-field"
                        type="password"
                        name="password"
                        spellCheck="false"
                        value={formData.password || ""}
                        placeholder="Password"
                        onChange={handleInputChange}
                    />
                    <FontAwesomeIcon icon={faLock} className="login-icon" />
                </div>
                <div className="login__btn-container">
                    <Button
                        className="regular-button"
                        disabled={status === "submitting"}
                        onClick={handleRegisterClick}
                        style={{ float: "left" }}
                    >
                        Register
                    </Button>
                    <Button
                        type="submit"
                        className="regular-button"
                        disabled={
                            !formData.username ||
                            !formData.password ||
                            status === "submitting"
                        }
                        style={{ float: "right" }}>
                        Sign in
                    </Button>

                </div>
            </form>
        </>
    )

}

export default LoginForm;