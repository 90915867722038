import './styles/styles.css';
import LoginForm from './Pages/Login';
import UserRegistrationForm from './Pages/Register';
import Navbar from './Layouts/Navbar';
import Cores from './Pages/Cores';
import Sticks from './Pages/Sticks';
import GameConfigs from './Pages/GameConfigs';
import { Route, Routes } from 'react-router-dom';
import React from 'react';
import RouteLayout from './Components/RouteLayout';
import RequireAuth from './Components/RequireAuth';

function App() {
  return(
    <>
    <Navbar />
    <Routes>
        <Route path="/" element={<RouteLayout />}>
            {/* public routes */}
            <Route path='login' element={<LoginForm />}/>
            <Route path='register' element={<UserRegistrationForm />}/>

            {/* private routes */}
            <Route element={<RequireAuth />}>

            <Route path='/' element={<Cores />}/>
            <Route path='sticks' element={<Sticks />}/>
            <Route path='gameconfigs' element={<GameConfigs />}/>

            </Route>

        </Route>
    </Routes>
    </>
  )
}

export default App;