import React from "react";
import TableRecord from "./TableRecord";

function TableHeaders({ datasets }) {
  return (
    <div className="table__header">
      <ul>
        <div key="name" className="name-attribute">
          <TableRecord>Name</TableRecord>
        </div>
        <div key="attributes" className="attributes-container">
          {Object.keys(datasets[0]).map((header) => {
            const formattedHeader = header
              .replace(/([A-Z])/g, " $1")
              .replace(/^./, (str) => str.toUpperCase());

            return <TableRecord key={header}>{formattedHeader}</TableRecord>;
          })}
        </div>
      </ul>
    </div>
  );
}

export default TableHeaders;