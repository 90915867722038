import React, { useState, useRef, useEffect } from "react";
import Title from "../Components/Title";
import { registerUserAsync } from "../Services/UserFacade";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock, faKey, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from "../Components/Button";
import { useNavigate } from 'react-router-dom';
import InfoTooltip from "../Components/InfoTooltip";

// must start with a letter
const USER_REGEX = /^[A-Za-z][A-Za-z0-9]{4,24}$/;
// must contain atleast 1 lower-case letter, 1 upper-case letter and 1 symbol
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{7,24}$/;
// must be a valid GUID
const KEY_REGEX = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/

function UserRegistrationForm () {
    // const [formData, setFormData] = useState({username:"", password:"", key:""});
    const [status, setStatus] = useState("typing");

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);

    const [key, setKey] = useState('');
    const [validKey, setValidKey] = useState(false);
    const [KeyFocus, setKeyFocus] = useState(false);

    const [errMessage, setErrMessage] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        const result = USER_REGEX.test(user);
        // console.log(result);
        // console.log(user);
        setValidName(result);
    }, [user])

    useEffect(() => {
        const result = PWD_REGEX.test(password);
        // console.log(result);
        // console.log(password);
        setValidPassword(result);
    }, [password])

    useEffect(() => {
        const result = KEY_REGEX.test(key);
        // console.log(result);
        // console.log(key);
        setValidKey(result);
    }, [key])

    useEffect(() => {
        setErrMessage('');
    }, [user, password, key])

    
    const handleSubmit = async (event) => {

        try {
            event.preventDefault();
            // we validate again to prevent JS tampering attacks
            const v1 = USER_REGEX.test(user);
            const v2 = PWD_REGEX.test(password);
            const v3 = KEY_REGEX.test(key);
    
            if(!v1 || !v2 || !v3){
                setErrMessage("Invalid Entry")
                setStatus("error")
                return;
            }
    
            setStatus("submitting");
            const response = await registerUserAsync(user, password, key);
            setStatus("success");
            // console.log(response);
        } catch (error) {
            setStatus("error")
        }
    };

    //#region input change handlers
    const handleUserInputChange = (event) => {
        setUser(event.target.value)
    }
    
    const handlePasswordInputChange = (event) => {
        setPassword(event.target.value)
    }
    
    const handleKeyInputChange = (event) => {
        setKey(event.target.value)
    }
    //#endregion
    
    //#region focus & blur handlers
    const handleUserFocus = () => {
        setUserFocus(true);
    }

    const handleUserBlur = () => {
        setUserFocus(false);
    }

    const handlePasswordFocus = () => {
        setPasswordFocus(true);
    }

    const handlePasswordBlur = () => {
        setPasswordFocus(false);
    }

    const handleKeyFocus = () => {
        setKeyFocus(true);
    }

    const handleKeyBlur = () => {
        setKeyFocus(false);
    }
    //#endregion

        const navigate = useNavigate(); 

        function handleClick() {
            navigate('/login');
        }

        let titleText = "Register";

    if (status === "error") {
        titleText = "Error";
    } else if (status === "submitting") {
        titleText = "Registering...";
    
    } else if (status === "success") {
        titleText = "You can now sign in";
    }

    return (
        <>
        <p ref={errRef} className={errMessage ? "errmsg" : "offscreen"}>{errMessage}</p>
        <Title containerClass="register-title">{titleText}</Title>
        <form onSubmit={handleSubmit} className="user-registration-form">
            {/* Username */}
            <div className="register__input-container">
                <span className={`validation__icon ${validName ? "valid" : "hide"}`}>
                    <FontAwesomeIcon icon={faCheck} />
                </span>
                <span className={`validation__icon ${validName || !user ? "hide" : "invalid"}`}>
                    <FontAwesomeIcon icon={faTimes} />
                </span>
                <input
                    className="register__input-field"
                    type="text"
                    name="username"
                    ref={userRef}
                    autoComplete="off"
                    required
                    spellCheck="false"
                    placeholder="Username"
                    onChange={handleUserInputChange}
                    onFocus={handleUserFocus}
                    onBlur={handleUserBlur}
                />
                <FontAwesomeIcon icon={faUser} className="login-icon" />
                <InfoTooltip text={`5 to 25 characters.\n
                 Must start with a letter\n
                 Can only contain upper and lower case letters and numbers`}/>
            </div>
            {/* Password */}
            <div className="register__input-container">
            <span className={`validation__icon ${validPassword ? "valid" : "hide"}`}>
                    <FontAwesomeIcon icon={faCheck} />
                </span>
                <span className={`validation__icon ${validPassword || !password ? "hide" : "invalid"}`}>
                    <FontAwesomeIcon icon={faTimes} />
                </span>
            <input
                className="register__input-field"
                type="password"
                required
                name="password"
                spellCheck="false"
                placeholder="Password"
                onChange={handlePasswordInputChange}
                onFocus={handlePasswordFocus}
                onBlur={handlePasswordBlur}
            />
            <FontAwesomeIcon icon={faLock} className="login-icon" />
            <InfoTooltip text={`8 to 25 characters.\n
            Must include upper and lower case letters, a number and a special character.\n
            Allowed special characters: !, @, #, $, %`} />
            </div>
            {/* Key */}
            <p className="key-information">This action requires a key</p>
            <div className="register__input-container">
            <span className={`validation__icon ${validKey ? "valid" : "hide"}`}>
                    <FontAwesomeIcon icon={faCheck} />
                </span>
                <span className={`validation__icon ${validKey || !key ? "hide" : "invalid"}`}>
                    <FontAwesomeIcon icon={faTimes} />
                </span>
                    <FontAwesomeIcon icon={faKey} className="login-icon" />
                <input
                    className="register__input-field"
                    type="text"
                    name="Key"
                    autoComplete="off"
                    spellCheck="false"
                    placeholder="Key"
                    onChange={handleKeyInputChange}
                    onFocus={handleKeyFocus}
                    onBlur={handleKeyBlur}
                />
                <InfoTooltip text={`Must be a valid Globally Unique ID`} />
            </div>
         
            <div className="login__btn-container">
            <Button
            type="submit"
            className="regular-button"
            disabled={!user ||
                !password ||
                !key ||
                status === "submitting"}
            style={{float: "right"}}
            >
               Register
            </Button>
            {/* Sign in button should be used to go back to login page */}
            <Button 
            className="regular-button"
            onClick={handleClick}
            style={{float: "left"}}>
                Sign in
            </Button>

            </div>
        </form>
        </>
    )

} 

export default UserRegistrationForm;