import Button from "./Button";

function DeleteConfirmation({context, onYes, onNo }){

    const handleNo = () => {
        onNo();
    }
    const handleYes = () => {
        onYes();
    }


return (
    <div className="delete-confirmation-container">
        <p className="delete-confirmation-text">Are you sure you want to delete this {context}?</p>
        <div className="confirmation-buttons-cointainer">
        <Button
        className="regular-button"
        onClick={handleNo}>
            No
        </Button>
        <Button
        className="regular-button"
        onClick={handleYes}>
            Yes
        </Button>

        </div>
    </div>
)
}

export default DeleteConfirmation;